export const ConfigData = [
  {
    id: "title_en",
    name: "Topic",
    isShow: true,
    width: "120px",
    filter: true,
  },
  {
    id: "app_module.name",
    name: "App Module",
    isShow: true,
    width: "130px",
    filter: true,
  },
  {
    id: "created_by.name",
    name: "Created By",
    isShow: true,
    width: "120px",
    filter: true,
  },
  {
    id: "created_on",
    name: "Created On",
    isShow: true,
    width: "160px",
    filter: true,
    format: "date-time"
  },
  {
    id: "updated_by.name",
    name: "Updated By",
    isShow: true,
    width: "120px",
    filter: true,
  },
  {
    id: "updated_on",
    name: "Updated On",
    isShow: true,
    width: "160px",
    filter: true,
    format: "date-time"
  },
  {
    id: "button",
    name: "Action",
    type: "dropdown",
    isShow: true,
    width: "117px",
    action: ["View Details", "Delete","Duplicate"],
    permission: {
      ["View Details"]: {
        id: "View Details",
        permission: "tutorial.access_tutorial",
      },
      Delete: {
        id: "Delete",
        permission: "tutorial.delete_tutorial",
      },
      ["Duplicate"]: {
        id: "Duplicate",
        permission: "tutorial.duplicate_tutorial",
      },
    },
  },
];

export const dropdownConfig = [
];

export const statusConfig = [
 
];

export const DefaulSorting = {
  id: "created_on",
  name: "Created On",
  isShow: true,
  width: "160px",
  filter: true,
};
