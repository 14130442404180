export const ConfigData = [
  {
    id: "to_user_businessarea",
    name: "Business Area",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "to_user_dept",
    name: "Department",
    isShow: true,
    width: "230px",
    filter: false,
    longIdentifier: true,
    longIdentifierObject: "department",
    returnLongIdentifier: "name",
  },
  {
    id: "to_user.name",
    name: "Name",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "to_user.designation",
    name: "Designation",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "cares_date",
    name: "CARES date",
    isShow: true,
    width: "160px",
    filter: true,
    format: "date",
  },
  {
    id: "message",
    name: "Commendation Message",
    isShow: true,
    width: "200px",
    filter: false,
  },
  {
    id: "recognised_by.name",
    name: "Approved By",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "status",
    name: "Status",
    isShow: true,
    width: "160px",
    filter: false,
  },
  {
    id: "button",
    name: "Action",
    type: "dropdown",
    isShow: true,
    width: "117px",
    action: ["View Details", "Publish", "Unpublish", "Delete"],
    permission: {
      Publish: {
        id: "Publish",
        permission: "cares.update_cares_ecard_status",
      },
      ["View Details"]: {
        id: "View Details",
        permission: "cares.access_cares_ecard_list",
      },
      Unpublish: {
        id: "Unpublish",
        permission: "cares.update_cares_ecard_status",
      },
      Delete: {
        id: "Delete",
        permission: "cares.delete_cares_ecard",
      },
    },
    isshowAction: {
      item: "status",
      children: [
        {
          id: "Publish",
          value: "published",
          notShowCondition: "Submitted",
        },
        {
          id: "Unpublish",
          value: "unpublished",
          notShowCondition: "Submitted",
        },
      ],
    },
  },
];

export const dropdownConfig = [
  {
    id: "to_user_businessarea",
    type: "dropdown",
    title: "Business Area",
    textField: "name",
    dataKeyItem: "code",
    dropdownIdentifier: "businessArea",
  },
  {
    id: "to_user_dept",
    type: "dropdown",
    title: "Department",
    textField: "name",
    dataKeyItem: "id",
    dropdownIdentifier: "department",
  },
  {
    id: "cares_date",
    type: "date",
    title: "Month",
  },
  {
    id: "status",
    type: "dropdown",
    title: "Status",
    textField: "name",
    dataKeyItem: "id",
    dropdownIdentifier: "status",
  },
  {
    id: "button",
    type: "button",
    title: "Approve",
  },
];

export const statusConfig = [
  {
    id: "Submitted",
    name: "Submitted",
  },
  {
    id: "Approved",
    name: "Approved",
  },
  {
    id: "Published",
    name: "Published",
  },
  {
    id: "Unpublished",
    name: "Unpublished",
  },
];

export const DefaulSorting = {
  id: "cares_date",
  name: "CARES date",
  isShow: true,
  width: "160px",
  filter: true,
};
