import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Stepper } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";

import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { CustomStep } from "../../../components/common/custom_steps";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import { Confirmation } from "../../../components/dialog/confirmation";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import "./manage_tutorial.css";
import moment from "moment";
import {
  ADMIN_TOOLS_TUTORIAL_LIST,
  ADMIN_TOOLS_TUTORIAL_ADD,
} from "../../../routes";
import {
  fetchTutorialAudit,
  fetcheTutorialDetailById,
  createAndUpdateTutorial,
} from "../../../store/manage_tutorial";
import { Button } from "@progress/kendo-react-buttons";
import EditIcon from "../../../assets/common/edit_icon.svg";
import ArrorRightIcon from "../../../assets/common/arrao-right.svg";

export const TUTORIAL_TABS = {
  EN: "English",
  CH: "Chinese",
};

export const TutorialDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { search } = useLocation();
  const [tab, setTab] = useState(TUTORIAL_TABS.EN);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(true);
  const query = new URLSearchParams(search);
  const id = query.get("id");
  const {
    detail_data,
    detail_loading,
    detail_error,
    audit_data,
    audit_loading,
  } = useSelector((state) => state.manageTutorial);
  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "tutorial.access_tutorial"
  );
  const checkDuplicate = checkPermission(
    userPermissions,
    "tutorial.duplicate_tutorial"
  );

  const editPermission = checkPermission(
    userPermissions,
    "tutorial.edit_tutorial"
  );

  const getData = async () => {
    dispatch(fetcheTutorialDetailById(id)).then((res) => {
      setDetailLoading(false);
      dispatch(fetchTutorialAudit(id));
    });
  };

  useEffect(() => {
    getData();
  }, [id]);

  const handleDuplicateEcard = () => {};

  const RenderRow = ({ title, value, isImage }) => {
    return (
      <>
        {value ? (
          <>
            <div className="tutorial_detail_child_box_main_detail_items">
              <div className="tutorial_detail_child_box_main_detail_items_title tutorial_detail_center_item detail_padding_bottom">
                {title}
              </div>
              <div className="tutorial_detail_child_box_main_detail_items_splitter" />
              <div className="tutorial_detail_center_item tutorial_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                {isImage ? (
                  <>
                    <img
                      style={{ maxWidth: "180px", height: "200px" }}
                      src={value}
                    />
                  </>
                ) : (
                  <>
                    <div dangerouslySetInnerHTML={{ __html: value }} />
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const formatDate = (date) => {
    const parsedDate = moment(new Date(date));
    return parsedDate.isValid()
      ? parsedDate.format("DD MMM YYYY, hh:mm a")
      : "-";
  };

  const onTabChange = (tab) => {
    setTab(tab);
  };

  return (
    <>
      {viewPermssion ? (
        <>
          {submitLoading && (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "9999",
              }}
            />
          )}
          {detail_loading || detailLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 75px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <div className="tutorial_detail_main_box">
              <div className="tutorial_detail_child_box">
                <div className="tutorial_detail_left_box">
                  <div
                    className="tutorial_detail_header"
                    //remove style if autosave needs to show
                    // style={{ minHeight: "50px" }}
                  >
                    <div
                      className="tutorial_detail_back"
                      onClick={() => navigate(ADMIN_TOOLS_TUTORIAL_LIST)}
                    >
                      <span className="k-icon k-font-icon k-i-arrow-chevron-left" />
                      Back
                    </div>
                    {editPermission && (
                      <div>
                        <div
                          className="editBtn"
                          onClick={() => {
                            navigate(
                              `${ADMIN_TOOLS_TUTORIAL_ADD}?id=${id}&isDuplicate=false`
                            );
                          }}
                        >
                          <img
                            src={EditIcon}
                            style={{ verticalAlign: "middle" }}
                          />
                          <span className="editBtn-label">Edit</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="tutorial_detail_child_box_main_detail_box">
                    <div>
                      <div className="tabs">
                        <div
                          onClick={() => onTabChange(TUTORIAL_TABS.EN)}
                          className={`tab ${
                            tab === TUTORIAL_TABS.EN ? "isactive" : ""
                          }`}
                        >
                          {TUTORIAL_TABS.EN}
                        </div>
                        <div style={{ borderLeft: "2px solid #CCCCCC" }}></div>
                        <div
                          onClick={() => onTabChange(TUTORIAL_TABS.CH)}
                          className={`tab ${
                            tab === TUTORIAL_TABS.CH ? "isactive" : ""
                          }`}
                        >
                          {TUTORIAL_TABS.CH}
                        </div>
                      </div>
                    </div>

                    {tab == TUTORIAL_TABS.EN ? (
                      // English UI
                      <div>
                        <RenderRow
                          title={"Module Name"}
                          value={detail_data.app_module.name}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Topic/Title"}
                          value={detail_data.title_en}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Created By"}
                          value={detail_data.created_by.name}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Created On"}
                          value={formatDate(detail_data.created_on)}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Updated By"}
                          value={
                            detail_data.updated_by.name
                              ? detail_data.updated_by.name
                              : "-"
                          }
                          isImage={false}
                        />
                        <RenderRow
                          title={"Updated On"}
                          value={formatDate(detail_data.updated_on)}
                          isImage={false}
                        />

                        <RenderRow
                          title={"Message 1"}
                          value={detail_data.message_en_1}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Image 1"}
                          value={detail_data.image_1}
                          isImage={true}
                        />
                        <RenderRow
                          title={"Message 2"}
                          value={detail_data.message_en_2}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Image 2"}
                          value={detail_data.image_2}
                          isImage={true}
                        />
                        
                        <RenderRow
                          title={"Message 3"}
                          value={detail_data.message_en_3}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Image 3"}
                          value={detail_data.image_3}
                          isImage={true}
                        />
                        
                        <RenderRow
                          title={"Message 4"}
                          value={detail_data.message_en_4}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Image 4"}
                          value={detail_data.image_4}
                          isImage={true}
                        />
                        
                        <RenderRow
                          title={"Message 5"}
                          value={detail_data.message_en_5}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Image 5"}
                          value={detail_data.image_5}
                          isImage={true}
                        />
                        
                      </div>
                    ) : (
                      // Chinese UI
                      <div>
                        <RenderRow
                          title={"Module Name"}
                          value={detail_data.app_module.name}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Topic/Title"}
                          value={detail_data.title_zh}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Created By"}
                          value={detail_data.created_by.name}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Created On"}
                          value={formatDate(detail_data.created_on)}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Updated By"}
                          value={
                            detail_data.updated_by.name
                              ? detail_data.updated_by.name
                              : "-"
                          }
                          isImage={false}
                        />
                        <RenderRow
                          title={"Updated On"}
                          value={formatDate(detail_data.updated_on)}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Message 1"}
                          value={detail_data.message_zh_1}
                          isImage={false}
                        />

                        <RenderRow
                          title={"Image 1"}
                          value={detail_data.image_zh_1}
                          isImage={true}
                        />
                        
                        <RenderRow
                          title={"Message 2"}
                          value={detail_data.message_zh_2}
                          isImage={false}
                        />

                        <RenderRow
                          title={"Image 2"}
                          value={detail_data.image_zh_2}
                          isImage={true}
                        />
                        
                        <RenderRow
                          title={"Message 3"}
                          value={detail_data.message_zh_3}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Image 3"}
                          value={detail_data.image_zh_3}
                          isImage={true}
                        />
                        
                        <RenderRow
                          title={"Message 4"}
                          value={detail_data.message_zh_4}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Image 4"}
                          value={detail_data.image_zh_4}
                          isImage={true}
                        />
                        
                        <RenderRow
                          title={"Message 5"}
                          value={detail_data.message_zh_5}
                          isImage={false}
                        />
                        <RenderRow
                          title={"Image 5"}
                          value={detail_data.image_zh_5}
                          isImage={true}
                        />
                        
                      </div>
                    )}
                  </div>
                </div>
                <div className="tutorial_detail_right_box">
                  <div className="tutorial_detail_right_box_action_main_box">
                    <div className="tutorial_detail_right_box_action_title">
                      Actions
                    </div>
                    {checkDuplicate && (
                      <div
                        className="tutorial_detail_right_box_action_delete"
                        onClick={() => {
                          navigate(
                            `${ADMIN_TOOLS_TUTORIAL_ADD}?id=${id}&isDuplicate=true`
                          );
                        }}
                      >
                        Duplicate &#x2192;
                      </div>
                    )}
                  </div>
                  <div className="tutorial_detail_right_box_action_main_box">
                    <div className="tutorial_detail_right_box_action_title">
                      Activity Trail
                    </div>
                    <div className={audit_data.length <= 1 && "audit_bar_hide"}>
                      {audit_loading ? (
                        <Loader
                          type="converging-spinner"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#ffffff4d",
                          }}
                        />
                      ) : (
                        <Stepper
                          orientation={"vertical"}
                          items={audit_data}
                          item={CustomStep}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
