import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Stepper } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import {
  deleteECard,
  fetchCaresAudit,
  fetchDepots,
  fetcheCardDetailById,
  updateECardVisibility,
} from "../../../store/ecards";
import {
  checkPermission,
  getUserPermission,
} from "../../../config/permission_helper";
import { CustomStep } from "../../../components/common/custom_steps";
import { PermissionDeninedPage } from "../../../components/reusableItems/permission_denined";
import { Confirmation } from "../../../components/dialog/confirmation";
import { ShowDialog } from "../../../components/reusableItems/dialog";
import { CARES_ECARDS_LISTING } from "../../../routes";
import "./ecard.css";
import moment from "moment";

export const EcardsDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [statusLoading, setStatusLoading] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [tempStatus, setTempStatus] = useState("");
  const [department, setDepartment] = useState([]);
  const [departmentLoading, setDepartmentLoading] = useState(false);
  const id = query.get("id");
  const {
    detail_data,
    detail_loading,
    detail_error,
    audit_data,
    audit_loading,
  } = useSelector((state) => state.ecard);
  const userPermissions = getUserPermission();
  const viewPermssion = checkPermission(
    userPermissions,
    "cares.access_cares_ecard_list"
  );
  const checkDelete = checkPermission(
    userPermissions,
    "cares.delete_cares_ecard"
  );
  const checkUpdateStatus = checkPermission(
    userPermissions,
    "cares.update_cares_ecard_status"
  );

  const getData = async () => {
    setDepartmentLoading(true);
    const depart = await dispatch(fetchDepots()).then((res) => {
      setDepartment(res.payload);
      setDepartmentLoading(false);
    });

    dispatch(fetcheCardDetailById(id)).then((res) => {
      dispatch(fetchCaresAudit(id));
    });
  };

  useEffect(() => {
    getData();
  }, [id]);

  const getDropdownData = (value) => {
    let permission = {
      Publish: {
        id: "published",
        permission: "cares.update_cares_ecard_status",
      },
      Unpublish: {
        id: "unpublished",
        permission: "cares.update_cares_ecard_status",
      },
    };
    if (value.length && value.length > 0) {
      let updateValue = [];
      value.map((i) => {
        const userPermission = getUserPermission();
        const checkStatusPermission = checkPermission(
          userPermission,
          permission?.[i].permission
        );
        if (checkStatusPermission) {
          if (
            detail_data.status.toLowerCase() === "submitted" &&
            detail_data.status.toLowerCase() !== permission?.[i].id
          ) {
            if (i === "Publish") {
              updateValue.push(i);
            }
          } else if (
            detail_data.status.toLowerCase() !== "submitted" &&
            detail_data.status.toLowerCase() !== permission?.[i].id
          ) {
            updateValue.push(i);
          }
        }
      });
      return updateValue;
    }
    return [];
  };

  const updateStatus = () => {
    setStatusLoading(true);
    setShowStatus(false);
    dispatch(
      updateECardVisibility({
        id: id,
        status: tempStatus === "Publish" ? "publish" : "unpublish",
      })
    ).then((res) => {
      //   if (res.payload.status === "success") {
      setStatusLoading(false);
      setTempStatus("");
      dispatch(fetcheCardDetailById(id)).then((res) => {
        dispatch(fetchCaresAudit(id));
      });
      //   } else {
      // alert(res.payload.message);
      //   }
    });
  };
  const handleDeleteEcard = () => {
    setStatusLoading(true);
    setShowDelete(false);
    dispatch(deleteECard(id)).then((res) => {
      navigate(CARES_ECARDS_LISTING);
    });
  };
  return (
    <>
      {showDelete && (
        <Confirmation
          title={"Please Confirm"}
          onClickNo={() => setShowDelete(false)}
          onClickYes={handleDeleteEcard}
        />
      )}
      {showStatus && (
        <ShowDialog
          cancelbtn={() => setShowStatus(false)}
          title={""}
          dropdown={true}
          cancelContent={"Cancel"}
          confrimContent={"Update"}
          confrinbtn={updateStatus}
          dropdownData={getDropdownData(["Publish", "Unpublish"])}
          dropdownOnChange={(e) => setTempStatus(e)}
          dropdownValue={tempStatus}
        />
      )}
      {viewPermssion ? (
        <>
          {detail_loading || statusLoading || departmentLoading ? (
            <Loader
              type="converging-spinner"
              style={{
                width: "100%",
                height: "calc(100% - 75px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "#ffffff4d",
                position: "absolute",
                zIndex: "999",
              }}
            />
          ) : (
            <div className="eCards_detail_main_box">
              <div className="eCards_detail_child_box">
                <div className="eCards_detail_left_box">
                  <div
                    className="eCards_detail_back"
                    onClick={() => navigate(CARES_ECARDS_LISTING)}
                  >
                    <span class="k-icon k-font-icon k-i-arrow-chevron-left" />
                    Back
                  </div>
                  <div className="eCards_detail_child_box_main_detail_box">
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        Visibility
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.status
                          ? detail_data.status
                          : "-"}
                      </div>
                    </div>
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        Business Area
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.to_user
                          ? detail_data.to_user.business_area
                          : "-"}
                      </div>
                    </div>
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        Department
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.to_user
                          ? department.length > 0 &&
                            department.find(
                              (i) => i.id === detail_data.to_user.department
                            )
                            ? department.length > 0 &&
                              department.find(
                                (i) => i.id === detail_data.to_user.department
                              ).name
                            : "-"
                          : "-"}
                      </div>
                    </div>
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        Name
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.to_user
                          ? detail_data.to_user.name
                          : "-"}
                      </div>
                    </div>
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        SAP/Emp No.
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.to_user
                          ? detail_data.to_user.id
                          : "-"}
                      </div>
                    </div>
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        Designation
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.to_user
                          ? detail_data.to_user.designation
                          : "-"}
                      </div>
                    </div>
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        CARES Date
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.cares_date
                          ? moment(new Date(detail_data.cares_date)).format(
                              "DD MMM YYYY"
                            )
                          : ""}
                      </div>
                    </div>
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        Service Standard
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.service_standard
                          ? detail_data.service_standard.map((i, id) => (
                              <p key={id}>
                                {i.number}. {i.description}
                              </p>
                            ))
                          : ""}
                      </div>
                    </div>
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        Commendation Message
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.message
                          ? detail_data.message
                          : ""}
                      </div>
                    </div>

                    <div className="eCards_detail_sender_by">Sending By</div>
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        Business Area
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.from_user
                          ? detail_data.from_user.business_area
                          : "-"}
                      </div>
                    </div>
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        Department
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.from_user
                          ? department.length > 0 &&
                            department.find(
                              (i) => i.id === detail_data.from_user.department
                            )
                            ? department.length > 0 &&
                              department.find(
                                (i) => i.id === detail_data.from_user.department
                              ).name
                            : "-"
                          : "-"}
                      </div>
                    </div>
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        Name
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.from_user
                          ? detail_data.from_user.name
                          : "-"}
                      </div>
                    </div>
                    {/* <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        Sender Info
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        {detail_data && detail_data.sender_info
                          ? detail_data.sender_info
                          : ""}
                      </div>
                    </div> */}
                    <div className="eCards_detail_child_box_main_detail_items">
                      <div className="eCards_detail_child_box_main_detail_items_title eCards_detail_center_item detail_padding_bottom">
                        eCard
                      </div>
                      <div className="eCards_detail_child_box_main_detail_items_splitter" />
                      <div className="eCards_detail_center_item eCards_detail_child_box_main_detail_items_subdetail detail_padding_bottom">
                        <img
                          style={{ maxWidth: "170px", height: "120px" }}
                          src={
                            detail_data &&
                            detail_data.image_url !== "" &&
                            detail_data.image_url
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="eCards_detail_right_box">
                  <div className="eCards_detail_right_status_box">
                    <div className="eCards_detail_right_box_status_title">
                      Status
                    </div>
                    <div className="eCards_detail_right_box_status_subdetail">
                      {detail_data && detail_data.status
                        ? detail_data.status
                        : "-"}
                    </div>
                  </div>
                  <div className="eCards_detail_right_box_action_main_box">
                    <div className="eCards_detail_right_box_action_title">
                      Actions
                    </div>
                    {checkUpdateStatus &&
                      detail_data &&
                      detail_data.status &&
                      detail_data.status.toLowerCase() !== "submitted" && (
                        <div
                          className="eCards_detail_right_box_action_edit"
                          onClick={() => setShowStatus(true)}
                        >
                          Edit Visibility &#x2192;
                        </div>
                      )}
                    {checkDelete && (
                      <div
                        className="eCards_detail_right_box_action_delete"
                        onClick={() => setShowDelete(true)}
                      >
                        Delete eCard &#x2192;
                      </div>
                    )}
                  </div>
                  <div className="eCards_detail_right_box_action_main_box">
                    <div className="eCards_detail_right_box_action_title">
                      Activity Trail
                    </div>
                    <div className={audit_data.length <= 1 && "audit_bar_hide"}>
                      {audit_loading ? (
                        <Loader
                          type="converging-spinner"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#ffffff4d",
                          }}
                        />
                      ) : (
                        <Stepper
                          orientation={"vertical"}
                          items={audit_data}
                          item={CustomStep}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <PermissionDeninedPage
          height={"calc(100% - 76px)"}
          title={"Sorry, You don't have permission to access this page!"}
        />
      )}
    </>
  );
};
